/* Dashboard.css */
.widget {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.widget-header {
    color: #0C3752;
    font-family: Oswald-Regular;
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    /* padding: 10px; */
    cursor: grab;
    /* Indicates draggable */
}

.widget-header:hover {
    background-color: #f1f9fe;
}

.widget-body {
    padding: 10px;
    flex-grow: 1;
}