.layout {
    width: 100%;
    /* Ensures the grid layout spans the full width */
    margin: 0 auto;
    /* Centers the layout */
    transform-origin: top left;
    /* Adjusts scaling origin */
}

.react-grid-placeholder {
    background-color: rgba(52, 152, 219, 0.5) !important;
    // background-color: #CDEBFF !important;
    /* Custom semi-transparent blue */
    border-radius: 8px;
    /* Optional: Adds rounded corners */
    transition: background-color 0.3s ease-in-out;
    /* Smooth transition effect */
}